import { Container } from "./styles";
import githubIcon from "../../assets/github.svg";
// import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <title>Folder</title>{" "}
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>{" "}
              </svg>
              <div className="project-links">
                <a href="https://sapost.ir" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>{" "}
              </div>
            </header>
            <div className="body">
              <h3>Sapost</h3>
              <p>
                {" "}
                I work at an innovative Iranian startup that developed SAPOST, a
                virtual mailbox service providing individuals with a personal
                postal code (SAPOSTID) to receive parcels and letters anywhere
                in the country without sharing a physical address. I redeveloped
                the SAPOST web panel and created the mobile app using React
                Native, ensuring a seamless user experience across platforms.{" "}
              </p>
            </div>
            <footer>
              {" "}
              <ul className="tech-list">
                {" "}
                <li>React</li> <li>React native</li> <li>MUI</li>{" "}
              </ul>{" "}
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>{" "}
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>{" "}
              </svg>
              <div className="project-links">
                <a href="https://carriot.ir" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Carriot</h3>
              <p>
                I worked as a Frontend Developer at Carriot, a company offering
                smart solutions to reduce costs, enhance safety, and improve
                decision-making for businesses with transportation fleets. My
                role included developing features for managing vehicle
                maintenance, fuel consumption, and generating detailed reports
                for fleet vehicles and drivers. I also implemented online
                vehicle monitoring and contributed to refactoring and
                maintaining the fleet panel. Additionally, I assisted in
                building an MVP for an insurance application based on surveys.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>React-native</li>
                <li>Django</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://www.atlas-nordic.com/"
                  target="\_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Atlas Nordic - Real Estate CRM Panel</h3>
              <p>
                My collaboration in building the CRM panel for Atlas Nordic has
                been driven by a commitment to excellence, user satisfaction,
                and contributing to the success of a globally recognized real
                estate company. I am proud to have been a part of this impactful
                project, and I look forward to further elevating Atlas Nordic's
                capabilities in the real estate industry.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Next.js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://morrowph.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Morrow: Food Ordering in the Philippines</h3>
              <p>
                My contribution to Morrow's front-end development was driven by
                a commitment to delivering an exceptional user experience
                tailored specifically for the Philippines. I am proud to have
                played a role in bringing Morrow to life, and I look forward to
                seeing how it enriches the food ordering landscape in the
                country.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Next.js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Code</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://youtu.be/NOm-3MynPLE"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Ride radar</h3>
              <p>
                Embark on a seamless delivery experience with RideRadar, a
                groundbreaking centralized platform designed to cater to all
                your delivery needs. As the "Trivago" of on-demand delivery
                services, RideRadar serves as a one-stop solution for users,
                offering the ability to effortlessly find, compare, and book the
                BEST and most COST-EFFECTIVE delivery options available. I
                played a pivotal role in the development of RideRadar's user
                interface, contributing to the creation of a visually appealing
                and highly functional front-end. By leveraging my front-end
                engineering skills, I have actively participated in crafting an
                interface that enhances user experience and ensures seamless
                navigation throughout the platform.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Next.js</li>
                <li>Monorepo</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links"></div>
            </header>
            <div className="body">
              <h3>Sales Management App for Pakhshenik (Private)</h3>
              <p>
                Sales Management App for PakhshenikSales Management App for
                Pakhshenik Jan 2020 - Feb 2020Jan 2020 - Feb 2020 Associated
                with FreelanceAssociated with Freelance Show project I'm proud
                to have developed a simple and efficient app to help manage
                sales representatives and vendors for a cosmetic products
                distribution company. This app empowers administrators to
                effortlessly add customers, update products, and enables sales
                representatives to register and track invoices.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Typescript</li>
                <li>Html</li>
                <li>css</li>
                <li>Express.js</li>
                <li>Nginx</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links"></div>
            </header>
            <div className="body">
              <h3>Corporate Website for OCUZ Cosmetics</h3>
              <p>
                corporate website tailored for OCUZ Cosmetics, aimed at
                showcasing the brand's exquisite range of beauty products. This
                dynamic platform has been meticulously designed to provide an
                engaging and immersive experience for visitors, allowing them to
                explore and appreciate the diverse offerings from OCUZ
                Cosmetics.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Typescript</li>
                <li>Html</li>
                <li>css</li>
                <li>Express.js</li>
                <li>Nginx</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}
